import { render, staticRenderFns } from "./poll.vue?vue&type=template&id=4b922546&scoped=true&"
import script from "./poll.vue?vue&type=script&lang=js&"
export * from "./poll.vue?vue&type=script&lang=js&"
import style0 from "./poll.vue?vue&type=style&index=0&id=4b922546&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b922546",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VContainer,VRow})
